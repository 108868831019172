import { ReactComponent as arrowLeft } from './assets/arrowLeft.svg';
import { ReactComponent as arrowRight } from './assets/arrowRight.svg';
import { ReactComponent as calendar } from './assets/calendar.svg';
import { ReactComponent as checkedCalendar } from './assets/checkedCalendar.svg';
import { ReactComponent as chevronDown } from './assets/chevronDown.svg';
import { ReactComponent as chevronLeft } from './assets/chevronLeft.svg';
import { ReactComponent as chevronRight } from './assets/chevronRight.svg';
import { ReactComponent as chevronUp } from './assets/chevronUp.svg';
import { ReactComponent as clock } from './assets/clock.svg';
import { ReactComponent as close } from './assets/close.svg';
import cuisineIcons from './assets/cuisines';
import { ReactComponent as dietTypeOmnivore } from './assets/dietTypeOmnivore.svg';
import { ReactComponent as dietTypePescatarian } from './assets/dietTypePescatarian.svg';
import { ReactComponent as dietTypeVegetarian } from './assets/dietTypeVegetarian.svg';
import { ReactComponent as heartFilled } from './assets/heartFilled.svg';
import { ReactComponent as heartOutline } from './assets/heartOutline.svg';
import { ReactComponent as homemade } from './assets/homemade.svg';
import { ReactComponent as info } from './assets/info.svg';
import { ReactComponent as leavesCircle } from './assets/leavesCircle.svg';
import { ReactComponent as main } from './assets/main.svg';
import { ReactComponent as message } from './assets/message.svg';
import { ReactComponent as minus } from './assets/minus.svg';
import { ReactComponent as mixingBowl } from './assets/mixingBowl.svg';
import { ReactComponent as movingDish } from './assets/movingDish.svg';
import { ReactComponent as openPot } from './assets/openPot.svg';
import { ReactComponent as plus } from './assets/plus.svg';
import { ReactComponent as plusHandWritten } from './assets/plusHandWritten.svg';
import { ReactComponent as portraitAdult1 } from './assets/portraitAdult1.svg';
import { ReactComponent as portraitAdult2 } from './assets/portraitAdult2.svg';
import { ReactComponent as portraitAdult3 } from './assets/portraitAdult3.svg';
import { ReactComponent as portraitChild1 } from './assets/portraitChild1.svg';
import { ReactComponent as portraitChild2 } from './assets/portraitChild2.svg';
import { ReactComponent as portraitChild3 } from './assets/portraitChild3.svg';
import { ReactComponent as portraitSmallChild } from './assets/portraitSmallChild.svg';
import { ReactComponent as promo } from './assets/promo.svg';
import { ReactComponent as proteinBeef } from './assets/proteinBeef.svg';
import { ReactComponent as proteinChicken } from './assets/proteinChicken.svg';
import { ReactComponent as proteinEgg } from './assets/proteinEgg.svg';
import { ReactComponent as proteinFish } from './assets/proteinFish.svg';
import { ReactComponent as proteinLamb } from './assets/proteinLamb.svg';
import { ReactComponent as proteinPlant } from './assets/proteinPlant.svg';
import { ReactComponent as proteinPork } from './assets/proteinPork.svg';
import { ReactComponent as proteinShellfish } from './assets/proteinShellfish.svg';
import { ReactComponent as proteinTofu } from './assets/proteinTofu.svg';
import { ReactComponent as questionMark } from './assets/questionMark.svg';
import { ReactComponent as restrictionDairyFree } from './assets/restrictionDairyFree.svg';
import { ReactComponent as restrictionGlutenFree } from './assets/restrictionGlutenFree.svg';
import { ReactComponent as restrictionOther } from './assets/restrictionOther.svg';
import { ReactComponent as reusablePackaging } from './assets/reusablePackaging.svg';
import { ReactComponent as sides } from './assets/sides.svg';
import { ReactComponent as starEmpty } from './assets/starEmpty.svg';
import { ReactComponent as starFilled } from './assets/starFilled.svg';
import { ReactComponent as starHalf } from './assets/starHalf.svg';
import { ReactComponent as thumbsDown } from './assets/thumbsDown.svg';
import { ReactComponent as thumbsUp } from './assets/thumbsUp.svg';
import { ReactComponent as verifiedCheck } from './assets/verifiedCheck.svg';

/**
 * When adding new icons, be sure that all colors in the SVG that should inherit the color are updated. This looks
 * like changing the property of all `<path>` and `<rect>` elements to use `currentColor` for any color you want
 * to be inherited (i.e. `fill="currentColor"` and `stroke="currentColor"`). Also be sure that the `<svg>` element
 * has `fill="none"` to make the background transparent.
 */

export const icons = {
  ...cuisineIcons,
  arrowLeft,
  arrowRight,
  calendar,
  checkedCalendar,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUp,
  clock,
  close,
  dietTypeOmnivore,
  dietTypePescatarian,
  dietTypeVegetarian,
  heartOutline,
  heartFilled,
  homemade,
  info,
  leavesCircle,
  main,
  message,
  minus,
  mixingBowl,
  movingDish,
  openPot,
  plus,
  portraitAdult1,
  portraitAdult2,
  portraitAdult3,
  portraitChild1,
  portraitChild2,
  portraitChild3,
  portraitSmallChild,
  promo,
  proteinBeef,
  proteinChicken,
  proteinEgg,
  proteinFish,
  proteinLamb,
  proteinPlant,
  proteinPork,
  proteinShellfish,
  proteinTofu,
  questionMark,
  restrictionDairyFree,
  restrictionGlutenFree,
  restrictionOther,
  reusablePackaging,
  sides,
  starHalf,
  starFilled,
  starEmpty,
  thumbsUp,
  thumbsDown,
  verifiedCheck,
  plusHandWritten,
};
