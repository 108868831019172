import { isValidPhoneNumber } from 'react-phone-number-input';

export const isValidPhone = (val?: string | null): boolean => {
  if (!val) return false;

  return isValidPhoneNumber(val);
};

export const formatPhone = (phone?: string | null): string | null => {
  if (!phone) {
    return null;
  }

  if (isValidPhone(phone)) {
    return phone;
  }

  // Some saved phone numbers don't have country codes, which our input considers invalid
  const withCountryCode = `+1${phone}`;

  if (isValidPhone(withCountryCode)) {
    return withCountryCode;
  }

  return null;
};
