import { checkFirstAndLastName } from '@shef/validators/dist/src/checkName';
import { ClientEvents } from 'common/events/ClientEvents';
import { EXP_VAR_TREATMENT, ExperimentNames } from 'common/experiments/ExperimentDefinitions';
import { isLeft } from 'fp-ts/lib/Either';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useVariant } from 'src/experimentation/useVariant';
import { AuthResult, CurrentUserFieldsFragment, OAuthLoginType } from 'src/gqlReactTypings.generated.d';
import { CheckboxInput } from 'src/shared/design-system/Input/CheckboxInput/CheckboxInput';
import { FormErrorMessage } from 'src/shared/design-system/Input/Error/FormErrorMessage';
import { DualLabeledTextInput } from 'src/shared/design-system/Input/LabeledTextInput/DualLabeledTextInput';
import { LabeledTextInput } from 'src/shared/design-system/Input/LabeledTextInput/LabeledTextInput';
import { useTextInputCallback } from 'src/shared/hooks/useInputCallback';
import { Routes } from 'src/shared/Routes';
import { formatPhone, isValidPhone } from 'src/shared/utils/PhoneUtils';
import styled from 'styled-components';
import Button, { ButtonType } from 'web-common/src/shared/design-system/components/Button';
import { Colors, Font, Spacing } from 'web-common/src/shared/styles';
import { useTracker } from '../../shared/hooks/useTracker';
import { OAUTH_LOGIN_TYPE_TO_AUTH_METHOD_RECORD, useLoginOrSignUpWithOAuth } from '../oAuth/useLoginOrSignUpWithOAuth';

interface OAuthAdditionalFieldsFormProps {
  additionalFields: AuthResult;
  token: string;
  type: OAuthLoginType;
  onSuccess: (isExistingUser: boolean, currentUser: CurrentUserFieldsFragment) => void;
  signUpEntryPoint?: string | null;
}

// Negative margin, since we're using gap for spacing
const ErrorMessageWithMargin = styled(FormErrorMessage)`
  margin-top: -${Spacing.HALF};
`;

const TOSText = styled.span`
  > a {
    color: ${Colors.GRAY_DARK_30} !important;
    font-weight: ${Font.Weight.BOLD};
    text-decoration: underline;
  }
`;

export const OAuthAdditionalFieldsForm: React.FC<OAuthAdditionalFieldsFormProps> = ({
  additionalFields,
  onSuccess,
  token,
  type,
  signUpEntryPoint,
}) => {
  const { variant } = useVariant(ExperimentNames.REQUIRE_PHONE_ON_SIGN_UP, true);
  const isVariantTreatment = variant?.name === EXP_VAR_TREATMENT;
  const [nameErrorMessage, setNameErrorMessage] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [firstName, onFirstNameChange] = useTextInputCallback(additionalFields.user.firstName ?? '');
  const [lastName, onLastNameChange] = useTextInputCallback(additionalFields.user.lastName ?? '');
  const [phoneErrorMessage, setPhoneErrorMessage] = useState<string | undefined>();
  const tracker = useTracker();
  const onError = () => {
    setErrorMessage('Login failed. Please try again, or try using a different method.');
  };

  const { login } = useLoginOrSignUpWithOAuth({
    type,
    onError,
    onSuccess,
    requireZipCode: false,
    signUpEntryPoint,
  });

  const [phone, onPhoneChange] = useTextInputCallback('');
  const [tosChecked, setTosChecked] = useState(false);

  const validateForm = () => {
    setErrorMessage(undefined);
    setNameErrorMessage(undefined);
    setPhoneErrorMessage(undefined);

    const nameCheck = checkFirstAndLastName({ firstName, lastName });
    if (isLeft(nameCheck)) {
      setNameErrorMessage(nameCheck.left);
    }

    const isPhoneValid = isVariantTreatment ? isValidPhone(formatPhone(phone) ?? phone) : true;
    if (!isPhoneValid) {
      setPhoneErrorMessage('Please enter a valid phone number');
    }

    if (!tosChecked) {
      setErrorMessage('You must accept our Terms of Service to continue');
    }

    return isPhoneValid && tosChecked;
  };

  const handleSubmitForm = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    const isFormValidated = validateForm();
    if (isFormValidated) {
      try {
        // additional fields form is only for facebook and google, apple uses missing fields form
        tracker.track(ClientEvents.LOGIN_MODAL_SIGNUP_SUBMIT, {
          method: type === OAuthLoginType.Facebook ? 'facebook' : 'google',
        });
        login(token, {
          firstName,
          lastName,
          phone: isVariantTreatment ? formatPhone(phone) ?? phone : undefined,
          hasAcceptedTermsOfService: tosChecked,
        });
        // REGISTER_SUCCESS does not fire in useHandleLogin for SSO, so we need it here
        tracker.track(ClientEvents.REGISTER_SUCCESS, {
          method: OAUTH_LOGIN_TYPE_TO_AUTH_METHOD_RECORD[type],
          promoCode: undefined,
        });
      } catch (error) {
        onError();
      }
    }
  };

  // Handle Enter key for form submission
  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmitForm();
    }
  };

  return (
    <form onSubmit={handleSubmitForm} onKeyDown={handleKeyDown} data-cy='additional-fields-form'>
      <fieldset className='flex flex-col gap-4 p-6'>
        <DualLabeledTextInput
          topLabel='First name'
          topName='firstname'
          topValue={firstName}
          onTopChange={onFirstNameChange}
          bottomLabel='Last name'
          bottomName='lastname'
          bottomValue={lastName}
          onBottomChange={onLastNameChange}
          errorMessage={nameErrorMessage}
          autoFocusTop
        />
        {isVariantTreatment && (
          <LabeledTextInput
            label='Phone Number'
            name='phone'
            value={phone}
            onChange={onPhoneChange}
            errorMessage={phoneErrorMessage}
            data-cy='additional-fields-phone'
          />
        )}
        <div>
          <CheckboxInput
            checked={tosChecked}
            onChange={() => setTosChecked((checked) => !checked)}
            data-cy='additional-fields-tos'>
            <TOSText>
              {'By selecting "Continue" you agree to Shef\'s '}
              <Link to={Routes.CONSUMER_TOS} target='_blank' className='primary font-bold !text-eggplant-400 underline'>
                Terms of Service
              </Link>
              {', '}
              <Link
                to={Routes.CONSUMER_PRIVACY_POLICY}
                target='_blank'
                className='primary font-bold !text-eggplant-400 underline'>
                Privacy Policy
              </Link>
              , and to receive text messages
            </TOSText>
          </CheckboxInput>
          {errorMessage ? <ErrorMessageWithMargin>{errorMessage}</ErrorMessageWithMargin> : null}
        </div>
        <Button buttonType={ButtonType.Primary} iconName='arrowRight' renderFullWidth disabled={!tosChecked}>
          Continue
        </Button>
      </fieldset>
    </form>
  );
};
