import { checkFirstAndLastName } from '@shef/validators/dist/src/checkName';
import { ZIP_CODE_REGEX } from 'common/Constants';
import { ClientEvents } from 'common/events/ClientEvents';
import { EXP_VAR_TREATMENT, ExperimentNames } from 'common/experiments/ExperimentDefinitions';
import { isLeft, isRight } from 'fp-ts/lib/Either';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useVariant } from 'src/experimentation/useVariant';
import { CurrentUserFieldsFragment, OAuthLoginType, OAuthMissingFieldsResult } from 'src/gqlReactTypings.generated.d';
import { CheckboxInput } from 'src/shared/design-system/Input/CheckboxInput/CheckboxInput';
import { FormErrorMessage } from 'src/shared/design-system/Input/Error/FormErrorMessage';
import { DualLabeledTextInput } from 'src/shared/design-system/Input/LabeledTextInput/DualLabeledTextInput';
import { LabeledTextInput } from 'src/shared/design-system/Input/LabeledTextInput/LabeledTextInput';
import { useTextInputCallback } from 'src/shared/hooks/useInputCallback';
import { Routes } from 'src/shared/Routes';
import { formatPhone, isValidPhone } from 'src/shared/utils/PhoneUtils';
import { UserPreferences } from 'src/user-preferences/userPreferencesTypes';
import { useUserPreferences } from 'src/user-preferences/useUserPreferences';
import styled from 'styled-components';
import { Colors, Font, Spacing } from 'web-common/src/shared/styles';
import { useTracker } from '../../shared/hooks/useTracker';
import { useLoginOrSignUpWithOAuth } from '../oAuth/useLoginOrSignUpWithOAuth';
import { AuthActionButton } from './AuthActionButton';

interface OAuthMissingFieldsFormProps {
  missingFields: OAuthMissingFieldsResult;
  token: string;
  type: OAuthLoginType;
  onSuccess: (isExistingUser: boolean, currentUser: CurrentUserFieldsFragment) => void;
  requireZipCode?: boolean;
  signUpEntryPoint?: string | null;
}

const TOSText = styled.span`
  > a {
    color: ${Colors.GRAY_DARK_30} !important;
    font-weight: ${Font.Weight.BOLD};
    text-decoration: underline;
  }
`;

// Negative margin, since we're using gap for spacing
const ErrorMessageWithMargin = styled(FormErrorMessage)`
  margin-top: -${Spacing.HALF};
`;

export const OAuthMissingFieldsForm: React.FC<OAuthMissingFieldsFormProps> = ({
  token,
  missingFields,
  onSuccess,
  type,
  requireZipCode,
  signUpEntryPoint,
}) => {
  const { variant } = useVariant(ExperimentNames.REQUIRE_PHONE_ON_SIGN_UP, true);
  const isVariantTreatment = variant?.name === EXP_VAR_TREATMENT;
  const [nameErrorMessage, setNameErrorMessage] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [zipCodeErrorMessage, setZipCodeErrorMessage] = useState<string | undefined>();
  const [phoneErrorMessage, setPhoneErrorMessage] = useState<string | undefined>();
  const [firstName, onFirstNameChange] = useTextInputCallback(missingFields.firstName ?? '');
  const [lastName, onLastNameChange] = useTextInputCallback(missingFields.lastName ?? '');
  const [zipCode, onZipCodeChange] = useTextInputCallback('');
  const [phone, onPhoneChange] = useTextInputCallback('');
  const [tosChecked, setTosChecked] = useState(false);
  const tracker = useTracker();

  const [, setPreference] = useUserPreferences();

  const onError = () => {
    setErrorMessage('Login failed. Please try again, or try using a different method.');
  };

  const { login } = useLoginOrSignUpWithOAuth({
    type,
    onError,
    onSuccess,
    requireZipCode,
    signUpEntryPoint,
  });

  const validateForm = () => {
    setErrorMessage(undefined);
    setNameErrorMessage(undefined);
    setPhoneErrorMessage(undefined);

    const nameCheck = checkFirstAndLastName({ firstName, lastName });
    if (isLeft(nameCheck)) {
      setNameErrorMessage(nameCheck.left);
    }

    const isZipCodeValid = !missingFields.zipCodeIsMissing || ZIP_CODE_REGEX.test(zipCode);
    if (!isZipCodeValid) {
      setZipCodeErrorMessage('Please enter a valid zip code');
    }

    const isPhoneValid = isVariantTreatment ? isValidPhone(formatPhone(phone) ?? phone) : true;
    if (!isPhoneValid) {
      setPhoneErrorMessage('Please enter a valid phone number');
    }

    if (!tosChecked && missingFields.needsToAcceptTermsOfService) {
      setErrorMessage('You must accept our Terms of Service to register');
    }

    return (
      isRight(nameCheck) && isZipCodeValid && isPhoneValid && (tosChecked || !missingFields.needsToAcceptTermsOfService)
    );
  };

  const handleSubmitForm = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    const isFormValidated = validateForm();
    if (isFormValidated) {
      if (missingFields.zipCodeIsMissing) {
        setPreference(UserPreferences.ZIPCODE, zipCode);
      }
      // missing fields form is only for apple, facebook and google use additional fields form
      tracker.track(ClientEvents.LOGIN_MODAL_SIGNUP_SUBMIT, { method: 'apple' });

      login(token, {
        firstName,
        lastName,
        zipCode,
        phone: isVariantTreatment ? formatPhone(phone) ?? phone : undefined,
        hasAcceptedTermsOfService: isNil(missingFields.needsToAcceptTermsOfService) ? undefined : tosChecked,
      });
    }
  };

  // Theoretically this shouldn't be needed – not sure why enter isn't handling form submission like normal
  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmitForm();
    }
  };

  return (
    <form onSubmit={handleSubmitForm} onKeyDown={handleKeyDown} data-cy='sign-up-form'>
      <fieldset className='flex flex-col gap-4 p-6'>
        <DualLabeledTextInput
          topLabel='First name'
          topName='firstname'
          topValue={firstName}
          onTopChange={onFirstNameChange}
          bottomLabel='Last name'
          bottomName='lastname'
          bottomValue={lastName}
          onBottomChange={onLastNameChange}
          errorMessage={nameErrorMessage}
          autoFocusTop
        />
        {missingFields.zipCodeIsMissing && (
          <LabeledTextInput
            label='Zip Code'
            name='zipCode'
            value={zipCode}
            onChange={onZipCodeChange}
            errorMessage={zipCodeErrorMessage}
            data-cy='sign-up-zip-code'
          />
        )}
        {isVariantTreatment && (
          <LabeledTextInput
            label='Phone Number'
            name='phone'
            value={phone}
            onChange={onPhoneChange}
            errorMessage={phoneErrorMessage}
            data-cy='sign-up-phone'
          />
        )}
        {missingFields.needsToAcceptTermsOfService ? (
          <CheckboxInput
            checked={tosChecked}
            onChange={() => setTosChecked((checked) => !checked)}
            data-cy='sign-up-tos'>
            <TOSText>
              {'By selecting "Sign up" you agree to Shef\'s '}
              <Link to={Routes.CONSUMER_TOS} target='_blank' className='primary'>
                Terms of Service
              </Link>
              {', '}
              <Link to={Routes.CONSUMER_PRIVACY_POLICY} target='_blank' className='primary'>
                Privacy Policy
              </Link>
              , and to receive text messages
            </TOSText>
          </CheckboxInput>
        ) : null}

        {errorMessage ? <ErrorMessageWithMargin>{errorMessage}</ErrorMessageWithMargin> : null}
        <AuthActionButton value='Sign up' />
      </fieldset>
    </form>
  );
};
