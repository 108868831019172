import type { HTMLAttributes } from 'react';
import { useMemo } from 'react';

import { icons } from './icons';

export type IconName = keyof typeof icons;

export enum IconColor {
  RASPBERRY = 'text-raspberry',
  EGGPLANT = 'text-eggplant',
  EGGPLANT_200 = 'text-eggplant-200',
  WHITE = 'text-white',
  EGGPLANT_400 = 'text-eggplant-400',
  EGGPLANT_500 = 'text-eggplant-500',
  GRAY = 'text-neutral-400',
  TURMERIC = 'text-turmeric',
}

type IconSize = 8 | 16 | 24 | 32 | 56;

interface Props extends HTMLAttributes<HTMLDivElement> {
  name: IconName;
  color?: IconColor;
  size?: IconSize;
  width?: IconSize;
  height?: IconSize;
}

/**
 *
 * @param name string key icon name
 * @param color optional string color of the icon
 * @param size optional number size of the icon: 8 | 16 | 24 | 32 | 56
 * @returns Icon react component
 */
export const Icon = ({ name, size = 16, width, height, color, id, ...rest }: Props) => {
  const SvgIcon = useMemo(() => icons[name], [name]);

  if (!SvgIcon) return null;

  return (
    <span {...rest} className={color} aria-label={name} role='img' id={id}>
      <SvgIcon height={height ?? size} width={width ?? size} id={id} />
    </span>
  );
};
