import { ApolloError } from '@apollo/client';
import { ClientEvents } from 'common/events/ClientEvents';
import React, { useEffect, useState } from 'react';
import {
  CurrentUserFieldsFragment,
  LoginMutation,
  useLoginMutation,
  useResetPasswordMutationMutation,
} from 'src/gqlReactTypings.generated.d';
import { CheckmarkCircle } from 'src/shared/design-system/Icon/CheckmarkCircle';
import { FormErrorMessage } from 'src/shared/design-system/Input/Error/FormErrorMessage';
import { LabeledTextInput } from 'src/shared/design-system/Input/LabeledTextInput/LabeledTextInput';
import { useTextInputCallback } from 'src/shared/hooks/useInputCallback';
import styled from 'styled-components';
import { Colors, Font, Spacing } from 'web-common/src/shared/styles';
import { useTracker } from '../../shared/hooks/useTracker';
import { useHandleLogin } from '../useHandleLogin';
import { AuthActionButton } from './AuthActionButton';
import { AuthSecondaryButton } from './AuthSecondaryButton';
import { useShowLoginOrSignUpModal } from './useShowLoginOrSignUpModal';

const Banner = styled.div`
  background: ${Colors.KOHLRABI_LITE_30};
  color: ${Colors.GRAY_DARK_30};
  font-family: ${Font.Family.SANS};
  font-size: ${Font.Size.S};
  font-weight: ${Font.Weight.SEMI_BOLD};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;
  padding: ${Spacing.HALF} ${Spacing.SINGLE_HALF} ${Spacing.HALF} ${Spacing.SINGLE_HALF};
`;

const StyledCheckmark = styled(CheckmarkCircle)`
  color: ${Colors.KOHLRABI};
  width: ${Spacing.SINGLE_HALF};
  height: ${Spacing.SINGLE_HALF};
  margin-right: ${Spacing.SINGLE};
`;

// Negative margin, since we're using gap for spacing
const ErrorMessageWithMargin = styled(FormErrorMessage)`
  margin-top: -${Spacing.HALF};
  align-self: flex-start;
`;

interface LoginWithEmailProps {
  email: string;
  onEmailChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onSuccess?: (isExistingUser: boolean, user: CurrentUserFieldsFragment) => void;
  goBack: () => void;
  errorMessage?: string;
  loginCTA?: string | null;
}

export const LoginWithEmail: React.FC<LoginWithEmailProps> = ({
  onSuccess,
  email,
  onEmailChange,
  goBack,
  errorMessage,
  loginCTA,
}) => {
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | undefined>();
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string | undefined>();
  const [genericErrorMessage, setGenericErrorMessage] = useState<string | undefined>(errorMessage);
  const [password, onPasswordChange] = useTextInputCallback('');
  const [bannerMessage, setBannerMessage] = useState<string | undefined>();

  const tracker = useTracker();
  const { signUpEntryPoint } = useShowLoginOrSignUpModal();
  const handleLogin = useHandleLogin();

  useEffect(() => {
    tracker.track(ClientEvents.LOGIN_MODAL_EMAIL_LOGIN_PAGE, {
      signUpEntryPoint,
    });
  }, [tracker]);

  const onLoginComplete = (data: LoginMutation) => {
    handleLogin('email', data.login);

    if (onSuccess) {
      onSuccess(data.login.isExistingUser, data.login.user);
    }
  };
  const onLoginError = (error: ApolloError) => {
    tracker.track(ClientEvents.LOGIN_FAILED, {
      method: 'email',
      errorMessage: error.message,
    });

    setPasswordErrorMessage('Invalid login credentials. Please try again.');
  };
  const [login, { loading: loginLoading }] = useLoginMutation({ onCompleted: onLoginComplete, onError: onLoginError });

  const [resetPasswordMutation, { loading: resetPasswordLoading }] = useResetPasswordMutationMutation({
    variables: { email },
  });
  const resetMessages = () => {
    setBannerMessage(undefined);
    setEmailErrorMessage(undefined);
    setPasswordErrorMessage(undefined);
    setGenericErrorMessage(undefined);
  };

  const requestLoading = resetPasswordLoading || loginLoading;

  const handleSubmitForm = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (requestLoading) {
      return;
    }

    resetMessages();

    const emailInputted = email.trim().length > 0;
    if (!emailInputted) {
      setEmailErrorMessage('Enter an email');
    }
    const passwordInputted = password.trim().length > 0;
    if (!passwordInputted) {
      setPasswordErrorMessage('Enter a password');
    }

    if (emailInputted && passwordInputted) {
      tracker.track(ClientEvents.LOGIN_MODAL_EMAIL_LOGIN_SUBMIT, { method: 'email' });
      login({ variables: { email, password } });
    }
  };

  const handleForgotPassword = (e: React.MouseEvent) => {
    e.preventDefault();
    resetMessages();

    tracker.track(ClientEvents.LOGIN_MODAL_FORGOT_PASSWORD_CLICKED, {
      signUpEntryPoint,
    });

    const emailInputted = email.trim().length > 0;
    if (!emailInputted) {
      setEmailErrorMessage('Enter an email');
    }

    // Optimistically set banner message. We don't throw or do anything if the email isn't registered.
    setBannerMessage(`A link to reset your password has been sent to ${email.trim()}`);
    if (emailInputted) {
      resetPasswordMutation({ variables: { email } });
    }
  };

  // Theoretically this shouldn't be needed – not sure why enter isn't handling form submission like normal
  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmitForm();
    }
  };

  return (
    <>
      {bannerMessage ? (
        <Banner>
          <StyledCheckmark />
          <span>{bannerMessage}</span>
        </Banner>
      ) : null}
      <form onSubmit={handleSubmitForm} onKeyDown={handleKeyDown} data-cy='login-form'>
        <fieldset className='flex flex-col items-center gap-2 p-6' disabled={requestLoading}>
          <LabeledTextInput
            label='Email'
            name='email'
            value={email}
            onChange={onEmailChange}
            errorMessage={emailErrorMessage}
            data-cy='login-email'
            disabled
          />
          <LabeledTextInput
            password
            label='Password'
            name='password'
            value={password}
            onChange={onPasswordChange}
            errorMessage={passwordErrorMessage}
            autoFocus
            data-cy='login-password'
          />
          {genericErrorMessage ? <ErrorMessageWithMargin>{genericErrorMessage}</ErrorMessageWithMargin> : null}
          <AuthActionButton value={loginCTA ?? 'Log in'} disabled={requestLoading} data-cy='login-button' />
          <AuthSecondaryButton onClick={goBack} label='Log in with different account' />
          <AuthSecondaryButton onClick={handleForgotPassword} label='Forgot password' />
        </fieldset>
      </form>
    </>
  );
};
