import { StorageKeys } from 'common/storage/constants';
import { QueryParams } from 'common/urls/QueryParams';
import React, { Suspense, useCallback, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, RouteChildrenProps } from 'react-router';
import { Switch } from 'react-router-dom';
import { LoginOrSignUpModal } from 'src/auth/modal/LoginOrSignUpModal';
import { useShowLoginOrSignUpModal } from 'src/auth/modal/useShowLoginOrSignUpModal';
import { isNativeMobileApp } from 'src/mobile-app/isNativeMobileApp';
import { TITLE } from 'src/shared/Constants';
import { useFeatureFlag } from 'src/shared/design-system/FeatureFlag';
import { useGoogleOneTapLogin } from 'src/shared/design-system/Login/useGoogleOneTapLogin';
import { ConsumerRoutes, Routes } from 'src/shared/Routes';
import { useLocalStorage } from 'src/shared/storage/Storage';
import { isDevEnvironment, isStagingEnvironment } from 'src/shared/utils/EnvironmentUtilities';
import { locationInRoutes } from 'src/shared/utils/RouteUtilities';
import { useScopedReducer } from 'src/shef-global-state/shefGlobalState';
import { TrackedRouteWithErrorBoundary } from 'src/tracking/tracked-route';
import { lazyRetry } from 'src/util/retry';
import { match as tsMatch } from 'ts-pattern';
import { StringParam, useQueryParam } from 'use-query-params';
import { LoadingScreenWithHeight } from 'web-common/src/shared/design-system/components/LoadingScreen';
import { ConsumerGroupOrderRatingModalContainer } from '../components/ratings/ConsumerGroupOrderRatingModal/ConsumerGroupOrderRatingModalContainer';
import { ZipCodeSelectionModalProvider } from './components/header-v2/ZipCodeSelection/ZipCodeSelectionModalContext';
import { SearchDataProvider } from './components/search/store/SearchDataProvider';
import { ConsumerBanner } from './ConsumerBanner';
import { MEAL_PLANS_INTAKE_FIRST_STEP } from './meal-plans/meal-plan-intake/preferences/consts';

const BecomeAShef = lazyRetry(() => import('src/pages/shef/become-a-shef/BecomeAShef'));
const Careers = lazyRetry(() => import('src/pages/careers/Careers'));
const CommunityGuidelines = lazyRetry(() => import('./static/ShefCommunityGuidelines'));
const ConsumerMultipleCartsDisplay = lazyRetry(() => import('./consumer-carts/ConsumerMultipleCartsDisplay'));
const ConsumerRefer = lazyRetry(() => import('src/pages/consumer/ConsumerRefer'));
const ConsumerExplore = lazyRetry(
  () => import(/* webpackPrefetch: true */ 'src/pages/consumer/dish-first/explore/DishFirstExplore')
);
const ConsumerExploreRedirect = lazyRetry(() => import('./explore/ConsumerExploreCuisineRedirectPage'));
const ConsumerExploreCuisine = lazyRetry(() => import('./explore-cuisine/ConsumerExploreCuisine'));
const ConsumerExploreMerchandise = lazyRetry(() => import('./explore-merchandise/ExploreMerchandise'));
const ConsumerFeePolicy = lazyRetry(() => import('./static/ConsumerFeePolicy'));
const ConsumerFollowing = lazyRetry(() => import('./follow-shef/FollowingPage'));
const ConsumerFooterWrapper = lazyRetry(() => import('./components/footer/ConsumerFooterWrapper'));
const ConsumerHeaderWrapper = lazyRetry(() => import('./components/ConsumerHeaderWrapper/ConsumerHeaderWrapper'));
const ConsumerLogin = lazyRetry(() => import('src/auth/page/LoginPage'));
const ConsumerOrderOneTime = lazyRetry(() => import('src/pages/consumer/order/ConsumerOrderOneTime'));
const ConsumerOrderCartMobile = lazyRetry(() => import('src/cart/ConsumerOrderCartMobile'));
const ConsumerOrderEditDelivery = lazyRetry(() => import('./order/ConsumerOrderEditDelivery'));
const ConsumerOrderEditLineItems = lazyRetry(() => import('./order/ConsumerOrderEditLineItems'));
const GroupOrderEditLineItems = lazyRetry(() => import('./order/GroupOrderEditLineItems'));
const ConsumerOrderEditCart = lazyRetry(() => import('./order/ConsumerOrderEditCart'));
const ConsumerOrderCancel = lazyRetry(() => import('./order/ConsumerOrderCancel'));
const ReorderEndpoint = lazyRetry(() => import('./order/reorder/ReorderEndpoint'));
const ConsumerOrderReceipt = lazyRetry(() => import('./order/receipt/ConsumerOrderReceipt'));
const ConsumerOrderFinalize = lazyRetry(() => import('src/checkout/ConsumerOrderFinalize'));
const ConsumerOrdersList = lazyRetry(() => import('./account/orders'));
const ConsumerGiftCardPurchase = lazyRetry(() => import('./ConsumerGiftCardPurchase'));
const ConsumerMessagesPage = lazyRetry(() => import('./account/messages/ConsumerMessagesPage'));
const ConsumerOrderSearch = lazyRetry(() => import('./search/SearchResults'));
const ConsumerMealPlanLandingPage = lazyRetry(() => import('src/rebrand/Homepage/MealPlans'));
const ConsumerMealPlanPreferences = lazyRetry(() => import('./meal-plans/meal-plan-intake/preferences'));
const ConsumerMealPlanCheckout = lazyRetry(() => import('./meal-plans/meal-plan-intake/checkout'));
const ConsumerMealPlanEdit = lazyRetry(() => import('./meal-plans/meal-plan-intake/edit'));
const ConsumerMealPlanUpcomingOrder = lazyRetry(() => import('./meal-plans/upcoming-order'));
const ConsumerMealPlanUpcomingGroupOrders = lazyRetry(() => import('./meal-plans/upcoming-order/index-revamp'));
const ConsumerMealPlansManage = lazyRetry(() => import('./meal-plans/manage/index'));
const ConsumerMealPlanManageDelivery = lazyRetry(() => import('./meal-plans/manage/delivery'));
const ConsumerMealPlansManagePlan = lazyRetry(() => import('./meal-plans/manage/plan'));
const ConsumerMealPlansManageTasteProfile = lazyRetry(() => import('./meal-plans/manage/taste-profile'));

const ConsumerPrivacyPolicy = lazyRetry(() => import('./static/ConsumerPrivacyPolicy'));
const ConsumerProfile = lazyRetry(() => import('./account/ConsumerProfile'));
const ConsumerNotifications = lazyRetry(() => import('./account/notifications/ConsumerNotifications'));
const ConsumerPromoHandler = lazyRetry(() => import('./components/ConsumerPromoHandler'));
const ConsumerResetChangePassword = lazyRetry(() => import('src/auth/page/ConsumerResetChangePassword'));
const ConsumerShare = lazyRetry(() => import('./account/refer/Refer'));
const ConsumerShareShef = lazyRetry(() => import('./account/refer/ReferShef'));
const ConsumerShareFoodItem = lazyRetry(() => import('./account/refer/ReferFoodItem'));
const ConsumerShareOrder = lazyRetry(() => import('./account/refer/ReferOrder'));
const ConsumerViewReferOrder = lazyRetry(() => import('./account/refer/ReferOrder/ViewReferOrder'));
const ConsumerTos = lazyRetry(() => import('./static/ConsumerTos'));
const ConsumerJun2022PickupOnlyTransitonInfo = lazyRetry(
  () => import('./static/ConsumerJun2022PickupOnlyTransitonInfo')
);
const Homepage = lazyRetry(() => import('./homepage'));
const ConsumerPaymentMethods = lazyRetry(() => import('./account/payment-methods/ConsumerPaymentMethods'));
const ConsumerDeliveryAddresses = lazyRetry(() => import('./account/delivery-addresses/ConsumerDeliveryAddresses'));
const WaitlistHome = lazyRetry(() => import('src/waitlists/WaitlistHome'));
const WaitlistActions = lazyRetry(() => import('src/waitlists/WaitlistActions'));
const WaitlistReferral = lazyRetry(() => import('src/waitlists/WaitlistReferral'));

const CelebrityEvent = lazyRetry(() => import('src/celebrity-event/CelebrityEventPage'));

const ConsumerMealPlansIndianRedirect = () => <Redirect to={`${Routes.CONSUMER_MEAL_PLANS}?community=indianmp`} />;
const ConsumerMealPlansYafRedirect = () => <Redirect to={`${Routes.CONSUMER_MEAL_PLANS}?community=yaf`} />;
const ConsumerMealPlansPreferencesYafRedirect = () => <Redirect to={MEAL_PLANS_INTAKE_FIRST_STEP} />;
export const ConsumerRouter: React.FC<RouteChildrenProps> = ({ match, history, location }) => {
  const [groupOrderIdForReview, setGroupOrderIdForReview] = useQueryParam(
    QueryParams.RATE_PREV_GROUP_ORDER_ID,
    StringParam
  );

  const isOnLoginOrSignUpPage = locationInRoutes(location, [
    ConsumerRoutes.CONSUMER_REGISTER,
    ConsumerRoutes.CONSUMER_LOGIN,
  ]);
  const isOnSubscriptionsIntake = locationInRoutes(location, [
    Routes.CONSUMER_MEAL_PLANS,
    Routes.CONSUMER_MEAL_PLANS_CHECKOUT,
    Routes.CONSUMER_MEAL_PLANS_EDIT,
    Routes.CONSUMER_MEAL_PLANS_UPCOMING_ORDER,
    Routes.CONSUMER_MEAL_PLANS_MANAGE,
    Routes.CONSUMER_MEAL_PLANS_MANAGE_DELIVERY,
    Routes.CONSUMER_MEAL_PLANS_MANAGE_PLAN,
    Routes.CONSUMER_MEAL_PLANS_MANAGE_TASTE_PROFILE,
    Routes.CONSUMER_MEAL_PLANS_PREFERENCES,
    Routes.CONSUMER_MEAL_PLANS_CHECKOUT,
  ]);
  const showManageMealPlans = useFeatureFlag('showManageMealPlans');
  const emptyHeldOrdersEnabled = useFeatureFlag('emptyHeldOrdersEnabled');

  // Keep track of urls for `useHomepageRedirects`
  const [, setLastVisitedUrl] = useLocalStorage<string>(StorageKeys.LAST_VISITED_URL, '');
  const [visitedMealPlans, setVisitedMealPlans] = useLocalStorage<boolean>(StorageKeys.VISITED_MEAL_PLANS, false);
  useEffect(() => {
    if (location.pathname !== Routes.CONSUMER_LANDING) {
      setLastVisitedUrl(location.pathname);
    }
    if (isOnSubscriptionsIntake && !visitedMealPlans) {
      setVisitedMealPlans(true);
    }
  }, [location.pathname, visitedMealPlans, isOnSubscriptionsIntake, setLastVisitedUrl, setVisitedMealPlans]);

  const hideGroupOrderRatingModal = useCallback(() => setGroupOrderIdForReview(undefined), [setGroupOrderIdForReview]);

  const dontRenderConsumerClass =
    match?.path === Routes.CONSUMER_LANDING || match?.path === Routes.CONSUMER_BECOME_A_SHEF;

  /** * Try to extract promo code from either referral url or promo param and put into PromoContext * */

  const backgroundRef = useRef<HTMLDivElement>(null);

  const { state: configs } = useScopedReducer('configs');
  const { profilePaymentMethodsEnabled, profileDeliveryAddressesEnabled, giftCardPurchaseEnabled, chatEnabled } =
    configs;

  useGoogleOneTapLogin(location);
  const { hide, isShowing } = useShowLoginOrSignUpModal();

  const header = (
    <div className='consumer shrink-0 grow-0'>
      <ConsumerPromoHandler location={location} history={history} match={match} />
      <ConsumerHeaderWrapper
        location={location}
        backgroundRef={backgroundRef}
        hideSearchAndSignup={isOnSubscriptionsIntake}
      />
    </div>
  );

  return (
    <SearchDataProvider>
      <ZipCodeSelectionModalProvider>
        <Helmet>
          <title>
            {tsMatch({ isDev: isDevEnvironment(), isStaging: isStagingEnvironment() })
              .with({ isDev: true }, () => 'Shef | localhost')
              .with({ isStaging: true }, () => 'Shef | staging')
              .otherwise(() => TITLE)}
          </title>
          <meta
            name='description'
            content='Looking for local homemade meals from neighborhood cooks who love cooking great authentic food? Try Shef for homemade meals delivered to your door!'
          />
        </Helmet>
        <div className='flex min-h-screen flex-col'>
          {isOnLoginOrSignUpPage ? null : (
            <>
              {header}
              <LoginOrSignUpModal hide={hide} isShowing={isShowing} />
            </>
          )}
          <div ref={backgroundRef} className='flex grow flex-col'>
            <ConsumerBanner />
            <div className={`flex grow flex-col${dontRenderConsumerClass ? '' : 'consumer'}`}>
              {groupOrderIdForReview ? (
                <ConsumerGroupOrderRatingModalContainer
                  groupOrderId={groupOrderIdForReview}
                  hide={hideGroupOrderRatingModal}
                />
              ) : null}

              <Suspense fallback={<LoadingScreenWithHeight />}>
                <Switch>
                  <TrackedRouteWithErrorBoundary path={Routes.CAREERS} component={Careers} />
                  <TrackedRouteWithErrorBoundary path={Routes.COMMUNITY_GUIDELINES} component={CommunityGuidelines} />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.DEPRECATED_CONSUMER_COMING_SOON}
                    component={WaitlistHome}
                  />

                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_ACCOUNT_SHARE_ORDER}
                    exact
                    component={ConsumerShareOrder}
                  />

                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_MEAL_PLANS_INDIAN_REDIRECT}
                    exact
                    component={ConsumerMealPlansIndianRedirect}
                  />

                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_MEAL_PLANS_YAF_REDIRECT}
                    exact
                    component={ConsumerMealPlansYafRedirect}
                  />

                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_MEAL_PLANS}
                    exact
                    component={ConsumerMealPlanLandingPage}
                  />

                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_MEAL_PLANS_PREFERENCES}
                    exact
                    component={ConsumerMealPlanPreferences}
                  />

                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_MEAL_PLANS_CHECKOUT}
                    exact
                    component={ConsumerMealPlanCheckout}
                  />

                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_MEAL_PLANS_EDIT}
                    exact
                    component={ConsumerMealPlanEdit}
                  />

                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_MEAL_PLANS_UPCOMING_ORDER}
                    exact
                    component={
                      emptyHeldOrdersEnabled ? ConsumerMealPlanUpcomingGroupOrders : ConsumerMealPlanUpcomingOrder
                    }
                  />

                  <TrackedRouteWithErrorBoundary path={Routes.CONSUMER_REFER} exact component={ConsumerRefer} />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_REFER_ORDER}
                    exact
                    component={ConsumerViewReferOrder}
                  />
                  <TrackedRouteWithErrorBoundary path={Routes.CONSUMER_REFER_SHEF} exact component={ConsumerRefer} />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_REFER_FOOD_ITEM}
                    exact
                    component={ConsumerRefer}
                  />
                  <TrackedRouteWithErrorBoundary path={Routes.CONSUMER_LOGIN} component={ConsumerLogin} />
                  <TrackedRouteWithErrorBoundary path={Routes.CONSUMER_REGISTER} component={ConsumerLogin} />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_RESET_CHANGE_PASSWORD}
                    component={ConsumerResetChangePassword}
                  />

                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_EXPLORE_CUISINE}
                    component={ConsumerExploreRedirect}
                  />
                  <TrackedRouteWithErrorBoundary path={Routes.CONSUMER_EXPLORE} component={ConsumerExplore} />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_EXPLORE_MERCHANDISE}
                    component={ConsumerExploreMerchandise}
                  />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_MULTIPLE_CARTS_VIEW}
                    component={ConsumerMultipleCartsDisplay}
                  />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_ORDER_ONE_TIME}
                    exact
                    component={ConsumerOrderOneTime}
                  />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_ORDER_FOOD_ITEM}
                    exact
                    component={ConsumerOrderOneTime}
                  />

                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_ORDER_SEARCH}
                    exact
                    component={ConsumerOrderSearch}
                  />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_ORDER_CART}
                    component={ConsumerOrderCartMobile}
                  />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_ORDER_FINALIZE}
                    component={ConsumerOrderFinalize}
                  />
                  <TrackedRouteWithErrorBoundary path={Routes.CONSUMER_ACCOUNT_PROFILE} component={ConsumerProfile} />
                  <TrackedRouteWithErrorBoundary path={Routes.CONSUMER_ACCOUNT_SHARE} exact component={ConsumerShare} />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_ACCOUNT_SHARE_SHEF}
                    exact
                    component={ConsumerShareShef}
                  />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_ACCOUNT_SHARE_FOOD_ITEM}
                    exact
                    component={ConsumerShareFoodItem}
                  />
                  {profilePaymentMethodsEnabled && (
                    <TrackedRouteWithErrorBoundary
                      path={Routes.CONSUMER_ACCOUNT_PAYMENT_METHODS}
                      component={ConsumerPaymentMethods}
                    />
                  )}
                  {profileDeliveryAddressesEnabled && (
                    <TrackedRouteWithErrorBoundary
                      path={Routes.CONSUMER_ACCOUNT_DELIVERY_ADDRESSES}
                      component={ConsumerDeliveryAddresses}
                    />
                  )}
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_ACCOUNT_NOTIFICATIONS}
                    component={ConsumerNotifications}
                  />
                  {giftCardPurchaseEnabled && (
                    <TrackedRouteWithErrorBoundary
                      path={Routes.CONSUMER_GIFT_CARD}
                      component={ConsumerGiftCardPurchase}
                    />
                  )}
                  <TrackedRouteWithErrorBoundary path={Routes.CONSUMER_ACCOUNT_ORDERS} component={ConsumerOrdersList} />
                  {chatEnabled && (
                    <TrackedRouteWithErrorBoundary
                      path={Routes.CONSUMER_ACCOUNT_MESSAGES}
                      component={ConsumerMessagesPage}
                    />
                  )}
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_ORDER_EDIT_DELIVERY}
                    component={ConsumerOrderEditDelivery}
                  />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_ORDER_EDIT_FOOD_ITEM}
                    exact
                    component={ConsumerOrderEditLineItems}
                  />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_ORDER_EDIT_GROUP_ORDER_FOOD_ITEM}
                    component={GroupOrderEditLineItems}
                  />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_ORDER_GROUP_ORDER_EDIT}
                    component={GroupOrderEditLineItems}
                  />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_ORDER_EDIT_LINE_ITEMS}
                    component={ConsumerOrderEditLineItems}
                  />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_ORDER_EDIT_CART}
                    component={ConsumerOrderEditCart}
                  />
                  <TrackedRouteWithErrorBoundary path={Routes.CONSUMER_ORDER_CANCEL} component={ConsumerOrderCancel} />
                  <TrackedRouteWithErrorBoundary path={Routes.CONSUMER_REORDER} component={ReorderEndpoint} />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_ORDER_CONFIRMATION}
                    component={ConsumerOrderReceipt}
                  />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_ORDERS_CONFIRMATION}
                    component={ConsumerOrderReceipt}
                  />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_ORDER_RECEIPT}
                    component={ConsumerOrderReceipt}
                  />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_PRIVACY_POLICY}
                    component={ConsumerPrivacyPolicy}
                  />
                  <TrackedRouteWithErrorBoundary path={Routes.CONSUMER_TOS} component={ConsumerTos} />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_JUN_2022_PICKUP_ONLY_MARKET_INFO}
                    component={ConsumerJun2022PickupOnlyTransitonInfo}
                  />
                  <TrackedRouteWithErrorBoundary path={Routes.CONSUMER_FEE_POLICY} component={ConsumerFeePolicy} />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_BECOME_A_SHEF_REGISTER}
                    component={BecomeAShef}
                  />
                  <TrackedRouteWithErrorBoundary path={Routes.CONSUMER_BECOME_A_SHEF} component={BecomeAShef} />
                  <TrackedRouteWithErrorBoundary path={Routes.CONSUMER_FOLLOWING} component={ConsumerFollowing} />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_EXPLORE_CUISINE_SEARCH}
                    component={ConsumerExploreCuisine}
                  />
                  {/* Keep this ordering for waitlist routes */}
                  <TrackedRouteWithErrorBoundary path={Routes.WAITLIST_ACTIONS_REFER} component={WaitlistReferral} />
                  <TrackedRouteWithErrorBoundary path={Routes.WAITLIST_ACTIONS} component={WaitlistActions} />
                  <TrackedRouteWithErrorBoundary path={Routes.WAITLIST_HOME} component={WaitlistHome} />
                  {showManageMealPlans && (
                    <TrackedRouteWithErrorBoundary
                      path={Routes.CONSUMER_MEAL_PLANS_MANAGE}
                      exact
                      component={ConsumerMealPlansManage}
                    />
                  )}

                  {showManageMealPlans && (
                    <TrackedRouteWithErrorBoundary
                      path={Routes.CONSUMER_MEAL_PLANS_MANAGE_DELIVERY}
                      exact
                      component={ConsumerMealPlanManageDelivery}
                    />
                  )}

                  {showManageMealPlans && (
                    <TrackedRouteWithErrorBoundary
                      path={Routes.CONSUMER_MEAL_PLANS_MANAGE_PLAN}
                      exact
                      component={ConsumerMealPlansManagePlan}
                    />
                  )}

                  {showManageMealPlans && (
                    <TrackedRouteWithErrorBoundary
                      path={Routes.CONSUMER_MEAL_PLANS_MANAGE_TASTE_PROFILE}
                      exact
                      component={ConsumerMealPlansManageTasteProfile}
                    />
                  )}

                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_PERSONAL_SHEF_FORM}
                    component={ConsumerMealPlansPreferencesYafRedirect}
                  />
                  <TrackedRouteWithErrorBoundary
                    path={Routes.CONSUMER_PERSONAL_SHEF}
                    component={ConsumerMealPlansYafRedirect}
                  />

                  {/* Celebrity Events */}
                  <TrackedRouteWithErrorBoundary path={Routes.BINGING_WITH_BABISH} component={CelebrityEvent} />
                  <TrackedRouteWithErrorBoundary path={Routes.MASTER_CHEF_NYESHA} component={CelebrityEvent} />

                  {/* Needs to be last */}
                  <TrackedRouteWithErrorBoundary path={Routes.CONSUMER_LANDING} component={Homepage} />
                </Switch>
              </Suspense>
            </div>
          </div>

          {isNativeMobileApp() || isOnLoginOrSignUpPage ? null : (
            <ConsumerFooterWrapper history={history} location={location} backgroundRef={backgroundRef} />
          )}
        </div>
      </ZipCodeSelectionModalProvider>
    </SearchDataProvider>
  );
};

export default ConsumerRouter;
