import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentUserFieldsFragment, PromoCodeFieldsFragment } from 'src/gqlReactTypings.generated.d';

export interface LoginStateOptions {
  signUpPromoCode?: PromoCodeFieldsFragment | null;
  onHideModalKey?: string;
  isClosable?: boolean;
  requireZipCode?: boolean;
  entryHeader?: string | null;
  signUpEntryPoint?: string | null;
  preventReloadOnLogin?: boolean;
  onSuccessfulLogin?: ((currentUser: CurrentUserFieldsFragment) => void) | null;
  signUpCTA?: string | null;
  continueCTA?: string | null;
  loginCTA?: string | null;
  hideBanner?: boolean | null;
  hideGuestOption?: boolean | null;
}

// TODO probably move currentUser to here
interface LoginState {
  isLoginModalShowing: boolean;
  options: LoginStateOptions | null;
}

export const initialState: LoginState = {
  isLoginModalShowing: false,
  options: null,
};

export const createLoginSlice = (initialState: LoginState) =>
  createSlice({
    name: 'login',
    initialState,
    reducers: {
      showLoginModal: (state, action: PayloadAction<LoginStateOptions | null>) => {
        if (state.isLoginModalShowing) {
          return state;
        }

        return { isLoginModalShowing: true, options: action.payload };
      },
      hideLoginModal: () => ({ isLoginModalShowing: false, options: null }),
    },
  });

export const loginSlice = createLoginSlice(initialState);

export type LoginSlice = typeof loginSlice;

export const { showLoginModal, hideLoginModal } = loginSlice.actions;
